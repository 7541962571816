import { ComponentProps, FC, ReactElement, useCallback } from 'react'
import tw from 'tailwind-styled-components'
import { Popup, Props as PopupProps, ChildrenProps } from './Popup'

type MenuItem = ReactElement<ComponentProps<typeof Item>>
type Props = Omit<PopupProps, 'children'> & {
  children: MenuItem | MenuItem[] | ((close: ChildrenProps['close']) => MenuItem | MenuItem[])
}

const Container = tw.div`flex flex-col py-1 rounded w-[200px]`
const Item = tw.p`
  py-1
  px-4
  flex
  flex-1
  text-primary-500
  font-medium
  hover:bg-gray-300
  hover:cursor-pointer
`

export const Menu: FC<Props> & { Item: typeof Item } = ({ children, ...props }) => {
  const renderChildren = useCallback(
    (close: ChildrenProps['close']) =>
      typeof children === 'function' ? (
        <Container>{children(close)}</Container>
      ) : (
        <Container>{children}</Container>
      ),
    [children]
  )
  return (
    <Popup position={'bottom right'} {...props}>
      {renderChildren}
    </Popup>
  )
}

Menu.Item = Item
