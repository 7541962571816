import { FC, HTMLAttributes } from 'react'
import tw from 'tailwind-styled-components'
import { SvgIcon } from './SvgIcon'
import Cross from '@foros-fe/assets/icons/times.svg'

const Container = tw.div`
  w-7
  h-7
  rounded
  bg-transparent
  hover:bg-gray-300
  active:bg-gray-400
  flex
  items-center
  justify-center
  transition
  duration-300
  cursor-pointer
`

export const CloseButton: FC<HTMLAttributes<HTMLDivElement>> = (props) => {
  return (
    <Container {...props}>
      <SvgIcon src={Cross} size={16} />
    </Container>
  )
}
