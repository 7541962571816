import { useCallback, useEffect, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { theme } from '../config'
import { humanDurationString } from '../utils'
import { DateTime } from 'luxon'

export * from './authentication'

export function useDeviceMediaQuery() {
  return {
    mobile: useMediaQuery({ query: `(max-width: ${parseInt(theme.screens.md) - 1}px)` }),
    tablet: useMediaQuery({
      query: `(min-width: ${parseInt(theme.screens.md)}px) and (max-width: ${
        parseInt(theme.screens.lg) - 1
      }px)`,
    }),
    desktop: useMediaQuery({ query: `(min-width: ${parseInt(theme.screens.lg)}px)` }),
  }
}

export const useScrollBlock = () => {
  const scrollPosition = useRef<number | null>(null)

  const blockScroll = useCallback(() => {
    if (typeof scrollPosition.current === 'number') return

    scrollPosition.current = window.scrollY

    const { body } = document
    body.style.inset = `-${scrollPosition.current}px 0px 0px`
    body.style.position = 'fixed'
  }, [scrollPosition])

  const allowScroll = useCallback(() => {
    if (typeof scrollPosition.current !== 'number') return

    const { body } = document
    body.style.inset = 'initial'
    body.style.position = 'initial'
    window.scrollTo(0, scrollPosition.current)

    scrollPosition.current = null
  }, [scrollPosition])

  return { blockScroll, allowScroll }
}

export function useDateTimeDuration(endTime?: string) {
  const active = endTime && DateTime.fromISO(endTime) > DateTime.local()
  const [timeLeft, setTimeLeft] = useState(active ? humanDurationString(endTime) : '')

  useEffect(() => {
    if (active) {
      const intervalID = setInterval(() => setTimeLeft(humanDurationString(endTime)), 1000)

      return () => clearInterval(intervalID)
    }
  }, [active, endTime])

  return timeLeft
}
