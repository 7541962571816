import ReactJSPopup from 'reactjs-popup'
import { PopupActions } from 'reactjs-popup/dist/types'
import { HTMLAttributes, ComponentProps, FC } from 'react'
import styled from 'styled-components'
import { theme } from '../config'

export type ChildrenProps = Pick<PopupActions, 'close'>
export type Props = HTMLAttributes<HTMLButtonElement> & ComponentProps<typeof ReactJSPopup>

const StyledPopup = styled(ReactJSPopup)`
  &-content {
    background: ${theme.colors.white};
    border: 1px solid ${theme.colors.gray[400]};
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
  }
  &-arrow {
    color: transparent;
  }
`

export const Popup: FC<Props> = (props) => {
  return <StyledPopup position={'bottom left'} keepTooltipInside closeOnDocumentClick {...props} />
}
