import {
  InputHTMLAttributes,
  forwardRef,
  ReactNode,
  useEffect,
  useRef,
  useImperativeHandle,
} from 'react'
import { FieldError, get, useFormContext } from 'react-hook-form'
import tw from 'tailwind-styled-components'
import { ErrorMessages } from '../ErrorMessages'

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'name'> & {
  name: string
  label?: ReactNode
  error?: FieldError
  indeterminate?: boolean
}
type Ref = HTMLInputElement | null

const Container = tw.div`flex flex-col items-start`
const Input = tw.input`
  form-checkbox
  appearance-none
  h-5
  w-5
  border-2
  border-secondary-500
  hover:border-secondary-600
  active:border-secondary-700
  rounded-md
  focus:text-secondary-500
  hover:bg-transparent
  checked:bg-secondary-500
  indeterminate:bg-secondary-500
  hover:checked:bg-secondary-600
  active:checked:bg-secondary-700
  hover:indeterminate:bg-secondary-600
  active:indeterminate:bg-secondary-700
  checked:border-transparent
  disabled:border-gray-400
  checked:disabled:bg-gray-400
  focus:ring-transparent
  transition
  duration-300
  cursor-pointer
`
const Label = tw.label`
  text-sm
  text-gray-700
  cursor-pointer
  flex
  items-center
`

export const CheckBox = forwardRef<HTMLInputElement, Props>(
  ({ name, label, error, className, indeterminate = false, ...props }, ref) => {
    const context = useFormContext()
    const inputRef = useRef<Ref>(null)
    useImperativeHandle<Ref, Ref>(ref, () => inputRef.current)

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.indeterminate = indeterminate
      }
    }, [inputRef, indeterminate, props.checked])

    return (
      <Container className={className}>
        <Label>
          <Input name={name} type='checkbox' ref={inputRef} {...props} />
          {label ? (
            <>
              &nbsp;
              <span>{label}</span>
            </>
          ) : null}
        </Label>
        <ErrorMessages error={error || get(context?.formState.errors, name)} className='ml-5' />
      </Container>
    )
  }
)
