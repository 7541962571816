import { useCallback } from 'react'
import { QueryHookOptions, QueryResult } from '@apollo/client'
import { PageInfo } from '../generated'

interface Node extends Object {
  [key: string]: any
}
export interface Model extends Node {
  id: string
}
type PaginatedResource = {
  pageInfo: Partial<PageInfo>
  nodes: Model[]
  __typename?: string
}

type QueryHook<Data, Variables> = (
  baseOptions?: QueryHookOptions<Data, Variables>
) => QueryResult<Data, Variables>
type QueryWithOptionsHook<Data, Variables> = (
  baseOptions: QueryHookOptions<Data, Variables>
) => QueryResult<Data, Variables>

type ResourceGetter<Data> = (data?: Data) => PaginatedResource | undefined

export const usePaginatedQuery = <Data extends Node, Variables>(
  useQuery: QueryHook<Data, Variables>,
  options: QueryHookOptions<Data, Variables>,
  resourceName: string
) => {
  const { fetchMore, data, ...result } = useQuery(options)

  const getResource = useCallback<ResourceGetter<Data>>(
    (data) => data?.[resourceName],
    [resourceName]
  )

  const pageInfo = getResource(data)?.pageInfo
  const after = pageInfo?.endCursor
  const hasNextPage = !!pageInfo?.hasNextPage

  const loadMoreResults = useCallback(
    (variables?: Variables) => fetchMore({ variables: { ...variables, after } }),
    [fetchMore, after]
  )

  return {
    ...result,
    data,
    fetchMore: loadMoreResults,
    hasNextPage,
  }
}

export const useImperativeQuery = <Data extends Node, Variables>(
  useQuery: QueryWithOptionsHook<Data, Variables>,
  options: QueryHookOptions<Data, Variables> = {}
) => {
  const { refetch } = useQuery({ ...options, skip: true })

  return (variables?: Partial<Variables>) => refetch(variables)
}
